<template>
  <div class="home__page">
    <DepositBanner />
    <Banners />
    <Games @setCategory="category = $event" :games="games" />
    <Winners v-if="displayWinners" />
    <!-- <FAQ v-if="category === 'all'" /> -->
    <FAQ v-if="shouldShowFAQ" />
  </div>
</template>

<script>
import Banners from "../components/Home/Banners";
import Games from "../components/Home/Games/Games";
import Winners from "../components/Home/Winners";
import DepositBanner from "../components/Home/DepositBanner";
import FAQ from "../components/Home/FAQ.vue";

export default {
  data() {
    return {
      category: "all",
    };
  },
  components: {
    DepositBanner,
    Winners,
    Games,
    Banners,
    FAQ,
  },
  computed: {
    games() {
      return this.$store.state.games;
    },
    auth() {
      return this.$store.state.auth;
    },
    displayWinners() {
      return this.$store.state.displayWinners;
    },
    shouldShowFAQ() {
      return this.category === "all" && !this.auth;
    },
  },
  beforeMount() {
    this.$store.dispatch("index");
  },
};
</script>

<style lang="scss" scoped>
.home__page {
  padding: 0 10px;
}
</style>
<style>
.wheel-container {
  z-index: 2;
}
</style>
